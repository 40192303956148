import React from "react"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Heading from "atomic-core/components/patterns/01-atoms/texts/heading/Heading"

import Text from "atomic-core/components/patterns/01-atoms/texts/text/Text"
import { Link } from "gatsby"
import { REGISTRATION_TYPE_MODERATED } from "../../../utils/constants"

const SuccessMessage = props => {
  const {
    url = "/help",
    heading = "Great news, your registration is complete.",
    text = "Please check your email for a link to log in to the event.",
    linkText = "",
  } = props

  return (
    <>
      <FontAwesomeIcon
        icon={faCheckCircle}
        size={"4x"}
        className={"color-primary-1"}
      />
      <Heading
        headingType={"h2"}
        globalModifiers={"pt-30 mb-0 font-primary-bold text-size-h3"}
      >
        {heading}
      </Heading>
      <Text globalModifiers={"text-size-medium font-secondary-regular mt-10"}>
        {text}
        {process.env.REGISTRATION_TYPE !== REGISTRATION_TYPE_MODERATED ? (
          <Link to={url} className={"link text-underline"}>
            {linkText}
          </Link>
        ) : (
          ""
        )}
      </Text>
    </>
  )
}

export default SuccessMessage
