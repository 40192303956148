import React, { useEffect, useState } from "react"
import { useQuery } from "react-query"
import useForm from "../../utils/useFormCustom"
import validate from "../../utils/validation/registerValidation"
import { Translations } from "../../utils/translations"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/free-solid-svg-icons"
import Agenda from "../agenda"
import {
  Container,
  Row,
  Column,
} from "atomic-core/components/patterns/00-base/grid"
import Heading from "atomic-core/components/patterns/01-atoms/texts/heading/Heading"
import Text from "atomic-core/components/patterns/01-atoms/texts/text/Text"
import FormItem from "atomic-core/components/patterns/02-molecules/forms/FormItem"
import AuthErrorMessage from "../shared/auth"
import SuccessMessage from "../shared/auth/SuccessMessage"
import Banner from "../shared/hero"
import Counter from "../shared/counter"

import { isBoolean } from "../../utils/global"
import { LOGIN_TYPE_NO_PASSWORD } from '../../utils/constants';
const Register = props => {
  const [agendaData, setagendaData] = useState({
    days: [],
    presentations: [],
    activeTabIndex: 0,
    title: "Agenda",
  })

  const { isLoading, data: formData } = useQuery(
    "getCustomRegistrationfield",
    () =>
      fetch(
        `${process.env.API_URL}/${process.env.EVENT_ID}/getCustomRegistrationfield`
      ).then(res => res.json()),
    {
      initialData: { registerationCustomFields: [] },
      refetchOnWindowFocus: false,
    }
  )
  const { registerationCustomFields = [] } = formData
  const defaultValues = registerationCustomFields.reduce(
    (obj, item) => Object.assign(obj, { [item.key]: "" }),
    {}
  )

  useEffect(() => {
    async function fetchData() {
      const response = await fetch(
        `${process.env.API_URL}/${process.env.EVENT_ID}/agenda`
      )
      let data = await response.json()
      if (data.result) {
        setagendaData({
          activeTabIndex: data.result.activeTabIndex,
          days: data.result.agenda.days,
          title: data.result.agenda.agenda_title,
          presentations: data.result.presentations,
        })
      }
    }
    fetchData()
  }, [])

  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)
  const [guestlistError, setGuestlistError] = useState(null)

  const handleRegister = async (
    { email, firstName, lastName, company, jobTitle, terms, ...args },
    resetForm
  ) => {
    try {
      if (!(typeof window !== `undefined`)) return false

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email,
          name: firstName,
          lastName,
          company,
          jobTitle,
          terms,
          customFormFields: {
            ...defaultValues,
            ...args,
          },
          eventId: process.env.EVENT_ID,
          hideIcs: process.env.HIDE_ICS === "true",
        }),
      }

      let response = await fetch(
        process.env.API_URL + "/registerv2",
        requestOptions
      )

      const contentType = response.headers.get("content-type")
      if (
        response.status !== 200 &&
        contentType &&
        contentType.indexOf("application/json") === -1
      ) {
        return setError(await response.text())
      }

      let data = await response.json()
      if (data && data.status === 200) {
        resetForm({})
        setSuccess(true)
      } else {
        setError(data.error)
      }
    } catch (e) {
      setError(e.message || Translations.ERROR.SOMETHING_WENT_WRONG)
    }
  }

  const { values, errors, handleChange, handleSubmit } = useForm(
    handleRegister,
    validate,
    setError,
    setSuccess,
    setGuestlistError,
    registerationCustomFields
  )

  registerationCustomFields.forEach(value => {
    if (value.type === "dropdown" && !values[value.key]) {
      values[value.key] = value.options[0]
    }
  })

  return (
    <div className={"page auth"}>
      <Container fluid globalModifiers={"d-xs-flex xs-column height-full"}>
        <Row globalModifiers={""}>
          <Banner />
        </Row>
        <Row globalModifiers={"content"}>
          <Column
            sm={12}
            md={6}
            globalModifiers={
              "background-color-monochrome-1 pt-60 pr-120 pb-60 pl-70"
            }
          >
            {!success && !isLoading ? (
              <>
                <Heading
                  headingType={"h2"}
                  globalModifiers={"pt-0 mb-0 font-primary-bold text-size-h3"}
                >
                  {Translations.registerPage.title}
                </Heading>
                <Text
                  globalModifiers={
                    "text-size-medium font-secondary-regular mt-10"
                  }
                >
                {
                  Translations?.registerPage?.[process.env.REGISTRATION_TYPE]?.description ?? ''
                }
                </Text>
                <form
                  onSubmit={handleSubmit}
                  method={"post"}
                  autoComplete={"off"}
                >
                  <FormItem globalModifiers={"mt-20 mb-20"}>
                    <div className={"form-item__control textfield"}>
                      <input
                        className={`form-control ${
                          errors.firstName ? "form-control--error" : ""
                        }`}
                        name="firstName"
                        type="text"
                        maxLength="20"
                        value={values.firstName || ""}
                        onChange={handleChange}
                        placeholder={"First name"}
                        autoComplete={"off"}
                      />
                    </div>
                    <div className={"form-item__error-message"}>
                      {errors.firstName}
                    </div>
                  </FormItem>

                  <FormItem globalModifiers={"mb-20"}>
                    <div className={"form-item__control textfield"}>
                      <input
                        className={`form-control ${
                          errors.lastName ? "form-control--error" : ""
                        }`}
                        name="lastName"
                        maxLength="20"
                        type="text"
                        value={values.lastName || ""}
                        onChange={handleChange}
                        placeholder={"Last name"}
                        autoComplete={"off"}
                      />
                    </div>
                    <div className={"form-item__error-message"}>
                      {errors.lastName}
                    </div>
                  </FormItem>

                  <FormItem globalModifiers={"mb-20"}>
                    <div className={"form-item__control textfield"}>
                      <input
                        className={`form-control ${
                          errors.company ? "form-control--error" : ""
                        }`}
                        name="company"
                        maxLength="50"
                        type="text"
                        value={values.company || ""}
                        onChange={handleChange}
                        placeholder={"Company name"}
                        autoComplete={"off"}
                      />
                    </div>
                    <div className={"form-item__error-message"}>
                      {errors.company}
                    </div>
                  </FormItem>

                  {process.env.IS_JOB_TITLE_MANDATORY ? (
                    <FormItem globalModifiers={"mb-20"}>
                      <div className={"form-item__control textfield"}>
                        <input
                          className={`form-control ${
                            errors.jobTitle ? "form-control--error" : ""
                          }`}
                          name="jobTitle"
                          maxLength="50"
                          type="text"
                          value={values.jobTitle || ""}
                          onChange={handleChange}
                          placeholder={"Job title"}
                          autoComplete={"off"}
                        />
                      </div>
                      <div className={"form-item__error-message"}>
                        {errors.jobTitle}
                      </div>
                    </FormItem>
                  ) : (
                    ""
                  )}

                  <FormItem globalModifiers={"mb-20"}>
                    <div className={"form-item__control textfield"}>
                      <input
                        className={`form-control ${
                          errors.email || guestlistError
                            ? "form-control--error"
                            : ""
                        }`}
                        name="email"
                        type="text"
                        value={values.email || ""}
                        onChange={handleChange}
                        placeholder={"Email address"}
                        autoComplete={"off"}
                      />
                    </div>
                    <div className={"form-item__error-message"}>
                      {errors.email || guestlistError}
                    </div>
                    {guestlistError ? <AuthErrorMessage /> : ""}
                  </FormItem>

                  {registerationCustomFields?.map((value, index) => {
                    if (value.type === "text") {
                      return (
                        <FormItem globalModifiers={"mb-20"} key={index}>
                          <div className={"form-item__control textfield"}>
                            <input
                              className={`form-control ${value.htmlClass} ${
                                errors[`${value.key}`]
                                  ? "form-control--error"
                                  : ""
                              }`}
                              name={`${value.key}`}
                              type="text"
                              value={values[value.key] || ""}
                              onChange={handleChange}
                              placeholder={`${value.name}`}
                              autoComplete={"off"}
                              {...value.attributes}
                              id={value.htmlId}
                            />
                          </div>
                          <div className={"form-item__error-message"}>
                            {errors[`${value.key}`]}
                          </div>
                        </FormItem>
                      )
                    } else if (value.type === "radio") {
                      return (
                        <FormItem globalModifiers={"mb-20"} key={index}>
                          <label className="form-item__label font-secondary-bold text-size-medium color-secondary-2">
                            {value.name}
                          </label>
                          {value.options?.map((option, optionindex) => {
                            return (
                              <div
                                key={option}
                                className={"form-item__control radio"}
                              >
                                <input
                                  type="radio"
                                  name={`${value.key}`}
                                  className={`form-control ${
                                    errors[`${option}`]
                                      ? "form-control--error"
                                      : ""
                                  }`}
                                  value={option}
                                  onChange={handleChange}
                                  id={`radio-${index}-${optionindex}`}
                                />
                                <label
                                  className="form-item__label"
                                  htmlFor={`radio-${index}-${optionindex}`}
                                  aria-label={option}
                                >
                                  <span
                                    className="icon icon-size-14 radius-circle"
                                    aria-hidden="true"
                                  >
                                    <i className="svg"></i>
                                  </span>
                                  <span
                                    className="form-item__label-text text-size-medium font-secondary-regular"
                                    aria-hidden="true"
                                  >
                                    {option}
                                  </span>
                                </label>
                              </div>
                            )
                          })}
                          <div className={"form-item__error-message"}>
                            {errors[`${value.key}`]}
                          </div>
                        </FormItem>
                      )
                    } else if (value.type === "dropdown") {
                      return (
                        <FormItem globalModifiers={"mb-20"} key={value.key}>
                          <label
                            htmlFor={value.name}
                            className="form-item__label text-size-medium font-secondary-bold color-secondary-2"
                          >
                            {value.name}
                          </label>
                          <select
                            id={`dropdown-${index}`}
                            name={value.key}
                            onBlur={handleChange}
                            className={`form-control select`}
                          >
                            {value.options?.map(option => {
                              return (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              )
                            })}
                          </select>
                          <div className={"form-item__error-message"}>
                            {errors[`${value.key}`]}
                          </div>
                        </FormItem>
                      )
                    } else if (value.type === "multiselect") {
                      return (
                        <FormItem globalModifiers={"mb-20"} key={index}>
                          <label className="form-item__label text-size-medium font-secondary-bold color-secondary-2">
                            {value.name}
                          </label>
                          {value.options?.map((option, optionindex) => {
                            return (
                              <div
                                key={option}
                                className={"form-item__control checkbox"}
                              >
                                <input
                                  type="checkbox"
                                  name={value.key}
                                  onChange={handleChange}
                                  className="form-control multiselect"
                                  id={`${option}-${optionindex}-${value._id}`}
                                  value={option}
                                />
                                <label
                                  className="form-item__label"
                                  htmlFor={`${option}-${optionindex}-${value._id}`}
                                >
                                  <span
                                    className="icon icon-size-14 mt-1"
                                    aria-hidden="true"
                                  >
                                    <FontAwesomeIcon
                                      className={"svg"}
                                      icon={faCheck}
                                      color={"rgb(176, 32, 114)"}
                                    />
                                  </span>
                                  <span
                                    className="form-item__label-text text-size-medium font-secondary-regular"
                                    aria-hidden="true"
                                  >
                                    {option}
                                  </span>
                                </label>
                              </div>
                            )
                          })}
                          <div className={"form-item__error-message"}>
                            {errors[`${value.key}`]}
                          </div>
                        </FormItem>
                      )
                    } else {
                      return ""
                    }
                  })}

                  <FormItem globalModifiers={"mb-40 mt-20"}>
                    <div className="form-item__control checkbox">
                      <input
                        type="checkbox"
                        name="terms"
                        onChange={handleChange}
                        className="form-control"
                        id="share-contacts"
                      />
                      <label
                        className="form-item__label"
                        htmlFor="share-contacts"
                        aria-label="I consent to my details being shared with the event organisers, for cookies being used on this site and for analytics to be captured. For the list of cookies used on this site, please see the Cookie Policy."
                      >
                        <span
                          className="icon icon-size-16 mt-1"
                          aria-hidden="true"
                        >
                          <FontAwesomeIcon
                            className={"svg"}
                            icon={faCheck}
                            color={"rgb(176, 32, 114)"}
                          />
                        </span>
                        <span
                          className="form-item__label-text text-size-medium font-secondary-regular"
                          aria-hidden="true"
                        >
                          I consent to my details being shared with the event organisers, for cookies being used on this site and for analytics to be captured. For the list of cookies used on this site, please see the{" "}
                          <a
                            className="link text-underline"
                            href="/cookie-policy"
                          >
                            Cookie Policy
                          </a>
                          .
                        </span>
                      </label>
                    </div>
                    <div className={"form-item__error-message"}>
                      {errors.terms}
                    </div>
                  </FormItem>

                  {error ? <AuthErrorMessage text={error} /> : ""}

                  <input
                    type="submit"
                    className={"button button--primary _a_register"}
                    value={"Register now"}
                  />
                </form>
              </>
            ) : (
              <div>
                <SuccessMessage
                  heading={Translations.SUCCESS.REGISTER_HEADING}
                  text={
                    Translations["SUCCESS"][
                      isBoolean(process.env.LOGIN_WITHOUT_PASSWORD)
                        ? LOGIN_TYPE_NO_PASSWORD
                        : process.env.REGISTRATION_TYPE
                    ]["REGISTER_TEXT"]
                  }
                  linkText="help page."
                />
              </div>
            )}
          </Column>
          <Column sm={12} md={6} globalModifiers={"pl-0 pr-0"}>
            <Counter />
            <div className="pt-30 pb-40 pl-40 pr-40">
              <Agenda
                days={agendaData.days}
                presentations={agendaData.presentations}
                startIndex={agendaData.activeTabIndex}
              />
            </div>
          </Column>
        </Row>
      </Container>
    </div>
  )
}

export default Register
