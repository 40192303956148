import React, { useEffect } from "react"
import "../wdyr"
import Register from "../components/user/register"
import { isLoggedIn } from "../services/auth"
import { navigate } from "gatsby"
import SEO from "../components/seo"
import { REGISTRATION_TYPE_NONE } from "../utils/constants"
const RegisterPage = props => {
  const isBrowser = () => typeof window !== "undefined"
  if (isBrowser() && isLoggedIn()) {
    navigate(`/dashboard`)
    return false
  }

  if (isBrowser() && process.env.REGISTRATION_TYPE === REGISTRATION_TYPE_NONE) {
    navigate(`/404`)
    return false
  }
  return (
    <>
      <SEO title="Register" />
      <Register />
    </>
  )
}

export default RegisterPage
