import { Translations } from "../translations"
import { isInputHtml, isValidEmail } from "../validation"

export default function validate(values, registerationCustomFields) {
  let errors = {}

  if (!values.email) {
    errors.email = Translations.ERROR.EMAIL_REQUIRED
  } else if (!isValidEmail(values.email)) {
    errors.email = Translations.ERROR.INVALID_EMAIL
  }

  if (!values.firstName) {
    errors.firstName = Translations.ERROR.FIRSTNAME_REQUIRED
  }

  if (!values.lastName) {
    errors.lastName = Translations.ERROR.LASTNAME_REQUIRED
  }

  if (!values.company) {
    errors.company = Translations.ERROR.COMPANY_NAME_REQUIRED
  } else if (
    isInputHtml(values.company)
  ) {
    errors.company = Translations.ERROR.HTML_JS_CODE_NOT_ALLOWE
  }

  if (process.env.IS_JOB_TITLE_MANDATORY) {
    if (!values.jobTitle) {
      errors.jobTitle = Translations.ERROR.JOB_TITLE_REQUIRED
    } else if (isInputHtml(values.jobTitle)) {
      errors.jobTitle = Translations.ERROR.HTML_JS_CODE_NOT_ALLOWE
    }
  }

  if (values.terms !== "true") {
    errors.terms = Translations.ERROR.CHECKBOX_SELECTION_REQUIRED
  }

  registerationCustomFields.map((field) => {
    if (field.isMandatory && !values[field.key]) {
      errors[field.key] = `Your ${field.name.toLowerCase()} is required.`
    } else if (values[field.key] && isInputHtml(values[field.key])) {
      errors[field.key] = Translations.ERROR.HTML_JS_CODE_NOT_ALLOWE
    }
  })

  return errors
}
